import React from 'react'

export const SubmitButton: React.FC<any> = ({status, index, setTitle, updateStatus}: {status: string[], index: number, setTitle: any, updateStatus: any}) => {
  const state = status[index]
  const title = setTitle(status[index], index)

  return (
    <button
      type="button"
      onClick={ () => updateStatus(index) }
      disabled={ state !== 'none' }
      className={ (state === 'finished' ? "bg-green-600" : ((state === 'pending') ? "bg-gray-700" : "bg-indigo-600 hover:bg-indigo-700")) + " inline-flex justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" }
    >
      { state === 'finished' ? `${title}` : ((state === 'pending') ? `${title}` : `${title}`) }
    </button>
  )
}